import OrderComponent from '~/shop/front/components/userProfile/SingleOrder'

class SingleOrder {
	status = ''
	processing = false
	form = false
	order = []
	component = OrderComponent

	constructor (app) {
		this.app = app
	}

	async fetchOrder (id) {
		const service = await this.app.getService('rext')
		this.order = await service.getOrder(id)
	}

	async init () {
		await this.fetchOrder(this.app.page.route.query.id)
		await this.app.translator.prefetch('client-profile.single_order')
	}
}
export {
	SingleOrder
}
