<template>
	<div class="order">
		<base-font marginReset variant="medium-header">
			{{ langs.details }}:<br>
			<span class="order-number">
				{{ order.id }}
			</span>
		</base-font>
		<div class="order-top-wrapper">
			<div class="order-header">
				<div class="order-upper-header">
					<base-font size="sm" weight="bold">
						{{ langs.date }}:
						<span class="order-info">
						{{ order.dateCreated | date }}
					</span>
					</base-font>
					<base-font size="sm" weight="bold">
						{{ langs.state }}:
						<span class="order-info">
						{{ langs.orderStatuses[order.status] }}
					</span>
					</base-font>
					<base-font size="sm" weight="bold">
						{{ langs.shipping }}:
						<span class="order-info">
						{{ langs.deliveryStatuses[order.delivery] }}
					</span>
					</base-font>
				</div>
				<div class="order-lower-header">
					<base-font size="sm" weight="bold">
						{{ langs.paymentMethod }}:
						<span class="order-info">
						{{ langs.paymentMethods[order.paymentMethod] }}
					</span>
					</base-font>
					<base-font size="sm" weight="bold">
						{{ langs.totalCost }}:
						<span class="order-info">
						<base-price :price="order.amount"/>
					</span>
					</base-font>
				</div>
			</div>
			<div class="order-discount-info" v-if="order.discountCode">
				<base-font size="sm" weight="bold">
					{{ langs.discountCode }}:
					<span class="order-info">
						{{ order.discountCode.code }} (-<base-price :price="discountValue"/>)
					</span>
				</base-font>
			</div>
		</div>
		<div class="table-wrapper">
			<table class="order-table">
				<thead>
					<tr >
						<th>
							{{langs.no}}
						</th>
						<th>
							{{langs.product}}
						</th>
						<th>
							{{langs.price}}
						</th>
						<th>
							{{langs.quantity}}
						</th>
						<th>
							{{langs.sum}}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, id) in orderItems" :key="id">
						<td >
							{{id + 1}}
						</td>
						<td>
							<div class='product-wrapper'>
								<cms-image
									class="cart-item-image"
									:src="item.product.thumbnail"
									:base="{width: 45, height: 45}"
									:lg="{width: 60 , height: 60}"
									:xxl="{width: 80 , height: 80}"
								/>
								<base-font>{{item.product.name}}</base-font>
							</div>
						</td>
						<td>
							<base-price :price="item.product.price"/>
						</td>
						<td>
							{{item.quantity}}
						</td>
						<td>
							<base-price :price="(item.product.price*item.quantity)"/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	computed: {
		langs () {
			return this.$app.translator.get('client-profile.single_order')
		},
		order () {
			return this.$app.page.view.order
		},
		orderItems () {
			return this.order.items
		},
		discountValue () {
			if (this.order.discountCode.type === 'percent') {
				return this.order.amount / 100 * this.order.discountCode.value.percent
			}
			return this.order.discountCode.value.discount
		}
	}
}
</script>
<style lang="scss" scoped>
	.order {
		&-number {
			color: $gold;
		}
		&-info {
			color: $green-500;
		}
		&-top-wrapper {
			margin-block: 3rem;

			.order-header {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				& > div {
					flex: 1 0 0;
				}

				@include media-breakpoint-up(xl) {
					flex-direction: row;
				}
			}
		}
		.table-wrapper{
			overflow-x: scroll;
			@include media-breakpoint-up(lg) {
				overflow: auto;
			}
		}
		&-table {
			width: 100%;
			text-align: center;
			font-weight: 500;
			text-transform: uppercase;
			th {
				padding: 0 1rem;
			}
			td {
				padding: 2rem;
			}

			.product-wrapper {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-align: left;
				width: auto;
			}
		}
	}
</style>
